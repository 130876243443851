const blockIndustries = async($block) => {
	const { Swiper } = await import( 'swiper' );
	const { Navigation } = await import( 'swiper/modules' );
	await import( 'swiper/css/bundle' );

	const industriesSlider = () => {
		const $slider = $block.find('.industries-tiles');

		if ( !$slider.length ) { return false; }

		const $sliderNav = $slider.find('.industries-tiles__nav');
		const $buttonNext = $slider.find('.slider-nav__next');
		const $buttonPrev = $slider.find('.slider-nav__prev');

		const industriesSwiper = new Swiper($slider[0], {
			modules: [Navigation],
			init: false,
			slidesPerView: 2,
			speed: 500,
			navigation: {
				nextEl: $buttonNext[0],
				prevEl: $buttonPrev[0]
			},
			breakpoints: {
				768: {
					slidesPerView: 'auto'
				},
				1024: {
					slidesPerView: 7
				}
			}
		});

		const checkNavVisibility = () => {
			if (!industriesSwiper.isBeginning || !industriesSwiper.isEnd) {
				$sliderNav.css('display', 'flex');
			} else {
				$sliderNav.css('display', 'none');
			}
		};

		industriesSwiper.on('init', function() {
			checkNavVisibility();
		});

		industriesSwiper.on('resize', function() {
			checkNavVisibility();
		});

		industriesSwiper.init();
	};

	industriesSlider();
};

themeUtils.loadBlock( blockIndustries, 'industries', '.section-industries' );
